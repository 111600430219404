import * as tools from './tools';

export function handleVatResult(result, host) {
  if (result.status === 401) {
    const ssoRedirect = tools.buildRedirect();
    window.location.assign(ssoRedirect);
  } else if (result.status === 201) {
    const currentTime = Date.now();
    tools.writeCookie('info_idp_vat_time', currentTime, host);
  }
  return result;
}

export function checkVat() {
  const timestamp = Number(tools.getCookie('info_idp_vat_time', 0));
  const now = Date.now();
  const delta = now - timestamp;
  const fourMinsInSeconds = 60 * 1000 * 4;

  if (delta >= fourMinsInSeconds) {
    const data = { infotoken: 'true', realm: 'idp' };
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    };
    const fetchArgs = { method: 'POST', headers: headers, body: JSON.stringify(data), credentials: 'include' };
    const host = tools.getHost();
    return fetch(`https://sso.${host}/v1/api/token/ui_heartbeat`, fetchArgs).then((response) => {return handleVatResult(response, host);});
  }
}

export function main() {
  if (!window.disableHeartbeat) {
    const loginInfoCookie = tools.getCookie('info_idp');
    if (loginInfoCookie) {
      _handleHeartbeat(loginInfoCookie);
    }
  }
}

function _handleHeartbeat(loginInfoCookie) {
  const loginInfo = JSON.parse(loginInfoCookie);
  const authType = loginInfo.auth;
  if (authType === 'basic') {
    const realm = loginInfo.typ;
    if (realm === 'idp') {
      let heartbeat = true;
      const { plid } = loginInfo;
      const queryPlid = tools.getPlidFromQuery();
      if (queryPlid) {
        if (Number(plid) !== Number(queryPlid)) {
          heartbeat = false;
        }
      }
      if (heartbeat) {
        const oneMin = 60 * 1000;
        setInterval(checkVat, oneMin);
        return checkVat();
      }
    }
  }
}

main();
